<template>
	<div>
		<CRow class="">
			<CCol sm="7">
				<CCard>
					<CCardHeader>
						<strong>FORM PENGAJUAN</strong> IJIN MASUK DAN BEKERJA DI DATA CENTER
					</CCardHeader>
					<CCardBody>
						<CForm>
							<!-- <CInput
								label="Tanggal Pengajuan"
								:value="time"
								horizontal
								plaintext
							/> -->

							<CRow>
								<CCol sm="12">
									<CSelect 
										:value.sync="site_id"
										name="site_id"
										label="Data Center"
										valid-feedback="Data Center Ditemukan."
                		invalid-feedback="Data Center Tidak Boleh Kosong."
										:is-valid="selectValidator"
										required
										placeholder="Pilih Lokasi Data Center"
										:options="sites"
										@change="siteChange($event)"
									/>
								</CCol>

								<CCol sm="12">
									<p>Ruangan</p>
								</CCol>

								<CCol sm="12" v-if="rooms.length < 1">
									<input inline type="checkbox" id="none" value="none" disabled>&nbsp;
									<label style="color: #e55353;"> Pilih Data Center Dahulu.</label>
								</CCol>

								<CCol sm="6" v-for="item in rooms" :key="item.id">
									<input inline type="checkbox" :id="item.value" :value="item.label" v-model="room_visits">&nbsp;
									<label :for="item.value"> {{item.label}}</label>
								</CCol>

								<CCol sm="12" class="mt-3">
									<CInput
										type="text"
										label="Rack / Cage"
										v-model="rack"
										placeholder=""
									/>
								</CCol>
							</CRow>

							<br><h6><strong>DETIL PERMOHONAN</strong></h6><br>

							<CRow>
								<CCol sm="6">
									<CSelect 
										:value.sync="activity_id"
										name="activity_id"
										label="Kegiatan"
										placeholder="Pilih Keperluan Kunjungan"
										:options="activities"
										valid-feedback="Keperluan Kunjungan Ditemukan."
                		invalid-feedback="Keperluan Kunjungan Wajib Diisi."
										:is-valid="selectValidator"
										required
									/>
								</CCol>
								<CCol sm="6">
									<CSelect 
										:value.sync="priority"
										name="priority"
										label="Prioritas"
										placeholder="Pilih Prioritas Kunjungan"
										:options="priorities"
										valid-feedback="Prioritas Kunjungan Valid."
                		invalid-feedback="Prioritas Kunjungan Wajib Diisi."
										:is-valid="selectValidatorPrior"
										required
									/>
								</CCol>
							</CRow>

							<CTextarea
								label="Detail Kegiatan"
								placeholder="Penjelasan singkat tentang kegiatan..."
								rows="4"
								v-model="description"
								valid-feedback="Detail Kegiatan Valid."
                invalid-feedback="Beri Keterangan Detail Kegiatan (minimal 7 karakter & 2 kata)."
								:is-valid="inputValidator"
								required
							/>

							<CRow>
								<CCol sm="3">
									<CInput
										type="date"
										label="Tanggal Mulai"
										placeholder=""
										description="Diisi tanggal."
										v-model="start_date"
										required
										@input="cekDate"
									/>
								</CCol>
								<CCol sm="3">
									<CInput
										type="time"
										label="Jam"
										description="Diisi jam."
										v-model="start_time"
										required
										@input="cekDate"
									/>
								</CCol>
								<CCol sm="3">
									<CInput
										type="date"
										label="Tanggal Selesai"
										placeholder=""
										description="Diisi tanggal."
										v-model="end_date"
										required
										@input="cekDate"
									/>
								</CCol>
								<CCol sm="3">
									<CInput
										type="time"
										label="Jam"
										description="Diisi jam."
										v-model="end_time"
										required
										@input="cekDate"
									/>
								</CCol>
							</CRow>

							<CRow>
								<CCol sm="6">
									<CSelect 
										:value.sync="unplugDevice"
										name="unplugDevice"
										label="Pasang / Cabut Perangkat"
										placeholder="Pilih.."
										:options="hardwares"
										@change="bastChange($event)"
										valid-feedback="Pasang/Cabut Perangkat Valid."
                		invalid-feedback="Cabut Perangkat Wajib Diisi."
										:is-valid="selectValidatorYN"
										required
									/>
								</CCol>
								<CCol sm="6">
									<CSelect 
										:value.sync="unplugCable"
										name="unplugCable"
										label="Pasang / Cabut Kabel"
										placeholder="Pilih.."
										:options="cables"
										valid-feedback="Pasang/Cabut Kabel Valid."
                		invalid-feedback="Cabut Kabel Wajib Diisi."
										:is-valid="selectValidatorYN"
										required
									/>
								</CCol>
							</CRow>

							<CRow class="mt-5">
								<CCol sm="6">
									<h6 v-if="workers !== []"><strong>DAFTAR NAMA PENGUNJUNG</strong></h6>
								</CCol>
								<CCol sm="6" class="col align-self-center row justify-content-end">
									<CButton
										v-if="company_id"
            				:color="newGuest ? 'danger' : 'success'"
            				variant="outline"
            				square
            				size="sm"
										class="mb-3"
										@click="toggleGuest"
          				>
            				{{newGuest ? 'Tutup' : 'Daftarkan Tamu'}}
          				</CButton>
								</CCol>
							</CRow>
							
							<CRow v-if="newGuest" style="border-top: 1px solid #ececee; padding-top: 8px;">
									<CInput
										type="number"
										v-model="guest.id"
										placeholder=""
										hidden
									/>
								<CCol sm="6">
									<CInput
										type="text"
										id="guestName"
										ref="guestName"
										label="Nama Sesuai KTP"
										v-model="guest.name"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="6">
									<CInput
										type="text"
										label="No. KTP"
										v-model="guest.id_card_number"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="4">
									<CInput
										:disabled="guest.id > 0 && guest.role !== 'Guest'"
										type="text"
										label="Nama Perusahaan"
										v-model="guest.company_name"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="4">
									<CInput
										:disabled="guest.id > 0"
										type="email"
										label="Email"
										v-model="guest.email"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="4">
									<CInput
										type="number"
										label="No. Handphone"
										v-model="guest.mobile_phone"
										placeholder="awalan 62"
										required
									/>
								</CCol>
								<CCol sm="9">
									<CAlert
                  	color="danger"
                  	closeButton
                  	v-if="showMessage"
                	>
                  	{{message}}
                	</CAlert>
								</CCol>
								<CCol sm="3">
									<CButton
            				color="primary"
            				variant="outline"
            				square
										block
            				size="sm"
										class="mb-3"
										@click="addGuest"
          				>
            				{{guest.id > 0 ? 'Ubah' : 'Daftarkan'}} <CSpinner grow color="100" size="sm" v-if="loading"/>
          				</CButton>
								</CCol>
							</CRow>
							
							<CDataTable
								v-if="!newGuest && workers !== []"
								hover
								striped
								small
								pagination
								:items="workers"
								:fields="fields"
								:items-per-page="5"
							>
								<template #action="{item}">
            			<td align="center">
              			<input
											type="checkbox"
											:id="item.name"
											:value="item.id"
											v-model="selected"
											@click="tracePic(item.role, $event)"
										>
            			</td>
          			</template>
								<template #delete="{item}">
            			<td align="center" style="cursor: pointer; color: #e55353;">
              			<!-- <CIcon v-if="item.role === 'Guest' && item.expired_at === null" size="sm" name="cil-trash" color="danger" @click="delGuest(item.id, item.company_name)"/> -->
										<a v-if="item.role === 'Guest' && item.expired_at === null" style="cursor: pointer; color: danger;" @click="delGuest(item.id, item.company_name)">
											<CIcon size="sm" name="cil-trash" color="danger" />
										</a>
            			</td>
          			</template>
								<template #name="{item}">
									<td>
										<a style="cursor: pointer; color: blue;" @click="editGuest(item.id)">{{item.name}}</a>
										<br><small><strong>{{item.company_name}}</strong></small>
									</td>
								</template>
								<template #email="{item}">
									<td>
										<a style="cursor: pointer;" v-if="item.signature">{{item.email}}</a>
										<a style="cursor: pointer;" v-if="!item.signature" @click="toast('Belum tandatangan NDA.', 'error')">{{item.email}}</a>
									</td>
								</template>
								<template #role="{item}">
									<td>
										<p v-if="!item.expired_at" style="cursor: pointer; color: #e55353;">{{item.role}}*</p>
										<p v-if="item.expired_at" style="cursor: pointer;">{{item.role}}</p>
									</td>
								</template>
								<template #expired_at="{item}">
									<td>
										<p>{{item.expired_at ? 'Valid' : 'Expired'}}</p>
									</td>
								</template>
								<template #company_name="{item}">
									<td>
										{{item.role === 'Guest' ? item.note : item.company_name}}
									</td>
								</template>
							</CDataTable>
							<p>
								<small>
									*) Bagi pengunjung baru, harap melakukan registrasi onsite di lokasi data center (aktivasi dengan melakukan tanda tangan NDA).
								</small>
							</p>

							<CRow class="mt-5" v-if="bastStatus">
								<CCol sm="6">
									<h6><strong>DAFTAR BARANG MASUK</strong></h6>
								</CCol>
								<CCol sm="6" class="col align-self-center row justify-content-end">
									<CButton
            				:color="newBastIn ? 'danger' : 'success'"
            				variant="outline"
            				square
            				size="sm"
										class="mb-3"
										@click="toggleBastIn"
          				>
            				{{newBastIn ? 'Tutup' : 'Tambahkan Barang'}}
          				</CButton>
								</CCol>
							</CRow>

							<CRow v-if="bastStatus && newBastIn" style="border-top: 1px solid #ececee; padding-top: 8px;">
								<CCol sm="6">
									<CInput
										type="text"
										label="Nama Barang"
										v-model="items.item_name"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="6">
									<CInput
										type="text"
										label="Serial Number / No. Reg LA"
										v-model="items.serial"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="4">
									<CInput
										type="number"
										label="Jumlah"
										v-model="items.total"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="4">
									<CInput
										type="text"
										label="Rack"
										v-model="items.rack"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="4">
									<CSelect 
										:value.sync="items.isDualFeed"
										name="items.isDualFeed"
										label="Dual Feed"
										placeholder="Pilih.."
										:options="dualFeed"
										required
									/>
								</CCol>
								<CCol sm="12">
									<CTextarea
										rows="3"
										label="Keterangan / Fungsi"
										v-model="items.note"
										required
									/>
								</CCol>
								<CCol sm="9">
									<CAlert
                  	color="danger"
                  	closeButton
                  	v-if="showMessage2"
                	>
                  	{{message2}}
                	</CAlert>
								</CCol>
								<CCol sm="3">
									<CButton
										v-if="items.id === 0"
            				color="primary"
            				variant="outline"
            				square
										block
            				size="sm"
										class="mb-3"
										@click="addItems('in')"
          				>
            				Tambahkan
          				</CButton>
									<CButton
										v-if="items.id > 0"
            				color="primary"
            				variant="outline"
            				square
										block
            				size="sm"
										class="mb-3"
										@click="toggleBastIn"
          				>
            				Update
          				</CButton>
								</CCol>
							</CRow>

							<CDataTable
								v-if="bastStatus && !newBastIn && bastIn !== []"
								hover
								striped
								small
								pagination
								:items="bastIn"
								:fields="fields3"
								:items-per-page="5"
							>
								<template #isDualFeed="{item}">
            			<td align="center">
              			{{item.isDualFeed === 0 ? 'Tidak' : 'Ya'}}
            			</td>
          			</template>
								<template #delete="{item}">
            			<td align="center" style="cursor: pointer;">
										<a style="cursor: pointer; color: danger;" @click="delItem('in', item)">
											<CIcon size="sm" name="cil-trash" color="danger" />
										</a>
            			</td>
          			</template>
								<template #item_name="{item}">
									<td>
										<a style="cursor: pointer; color: blue;" @click="editItem('in', item)">{{item.item_name}}</a>
									</td>
								</template>
							</CDataTable>

							<CRow class="mt-5" v-if="bastStatus">
								<CCol sm="6">
									<h6><strong>DAFTAR BARANG KELUAR</strong></h6>
								</CCol>
								<CCol sm="6" class="col align-self-center row justify-content-end">
									<CButton
            				:color="newBastOut ? 'danger' : 'success'"
            				variant="outline"
            				square
            				size="sm"
										class="mb-3"
										@click="toggleBastOut"
          				>
            				{{newBastOut ? 'Tutup' : 'Tambahkan Barang'}}
          				</CButton>
								</CCol>
							</CRow>

							<CRow v-if="bastStatus && newBastOut" style="border-top: 1px solid #ececee; padding-top: 8px;">
								<CCol sm="6">
									<CInput
										type="text"
										label="Nama Barang"
										v-model="items2.item_name"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="6">
									<CInput
										type="text"
										label="Serial Number / No. Reg LA"
										v-model="items2.serial"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="4">
									<CInput
										type="number"
										label="Jumlah"
										v-model="items2.total"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="4">
									<CInput
										type="text"
										label="Rack"
										v-model="items2.rack"
										placeholder=""
										required
									/>
								</CCol>
								<CCol sm="4">
									<CSelect 
										:value.sync="items2.isDualFeed"
										name="items.isDualFeed"
										label="Dual Feed"
										placeholder="Pilih.."
										:options="dualFeed"
										required
									/>
								</CCol>
								<CCol sm="12">
									<CTextarea
										rows="3"
										label="Keterangan / Fungsi"
										v-model="items2.note"
										required
									/>
								</CCol>
								<CCol sm="9">
									<CAlert
                  	color="danger"
                  	closeButton
                  	v-if="showMessage3"
                	>
                  	{{message3}}
                	</CAlert>
								</CCol>
								<CCol sm="3">
									<CButton
										v-if="items2.id === 0"
            				color="primary"
            				variant="outline"
            				square
										block
            				size="sm"
										class="mb-3"
										@click="addItems('out')"
          				>
            				Tambahkan
          				</CButton>
									<CButton
										v-if="items2.id > 0"
            				color="primary"
            				variant="outline"
            				square
										block
            				size="sm"
										class="mb-3"
										@click="toggleBastOut"
          				>
            				Update
          				</CButton>
								</CCol>
							</CRow>

							<CDataTable
								v-if="bastStatus && !newBastOut && bastOut !== []"
								hover
								striped
								small
								pagination
								:items="bastOut"
								:fields="fields3"
								:items-per-page="5"
							>
								<template #isDualFeed="{item}">
            			<td align="center">
              			{{item.isDualFeed === 0 ? 'Tidak' : 'Ya'}}
            			</td>
          			</template>
								<template #delete="{item}">
            			<td align="center" style="cursor: pointer;">
										<a style="cursor: pointer; color: danger;" @click="delItem('out', item)">
											<CIcon size="sm" name="cil-trash" color="danger" />
										</a>
            			</td>
          			</template>
								<template #item_name="{item}">
									<td>
										<a style="cursor: pointer; color: blue;" @click="editItem('out', item)">{{item.item_name}}</a>
									</td>
								</template>
							</CDataTable>

							<br><br>
							<input :disabled="room_visits.length === 0 || selected.length === 0 || !mandatory" type="checkbox" id="checkbox" v-model="isComply"> <span for="checkbox"> Mengetahui dan bersedia mematuhi Kebijakan Data Center Lintasarta yang berlaku.</span>
							
						</CForm>
					</CCardBody>
					<CCardFooter>
						<CButton type="submit" size="sm" color="primary" :disabled="!isComply" @click="formSubmit">
							<CIcon name="cil-check-circle"/> Submit <CSpinner grow color="100" size="sm" v-if="buttonLoading"/>
						</CButton> &nbsp;&nbsp;
						<CButton type="reset" size="sm" color="danger"><CIcon name="cil-ban"/> Reset</CButton>
					</CCardFooter>
				</CCard>
			</CCol>
			<CCol sm="5">
				<vue-position-sticky :offsetTop="90" sticky-class="myClass">
					<CCard class="">
					<CCardHeader>
						<strong>DAFTAR RENCANA KUNJUNGAN</strong> DARI PERUSAHAAN YANG SAMA
					</CCardHeader>
					<CCardBody>
						<h6><strong>STATUS:</strong> PENDING</h6>
						<CDataTable
							hover
							striped
							small
							:items="visits"
							:fields="fields2"
							:items-per-page="10"
							pagination
							:loading="isLoading"
						>
							<template #activity_name="{item}">
								<td><a style="cursor: pointer; color: blue;" @click="detailVisit(item.id)">{{item.activity_name}}</a></td>
							</template>
						</CDataTable>
					</CCardBody>
				</CCard>
				</vue-position-sticky>
			</CCol>
		</CRow>

		<!-- <CModal 
    	:show.sync="alertModal"
    	:no-close-on-backdrop="true"
    	:centered="true"
    	size="sm"
    	color="danger"
  	>
    	Anda memiliki daftar barang untuk diserahterimakan. Hapus ?
    	<template #header>
      	<h6 class="modal-title">PERHATIAN</h6>
      	<CButtonClose @click="logoutModal = false" class="text-white"/>
    	</template>
    	<template #footer>
      	<CButton @click="cancelDel" color="">Batal</CButton>
      	<CButton @click="basts = []" color="">Hapus</CButton>
    	</template>
  	</CModal> -->

	</div>
</template>

<script>
import http from '@/utils/http-common';
import moment from 'moment'

export default {
	name: 'VisitRequest',
	data () {
		return {
			company_id: +localStorage.getItem('company_id'),
			role: +localStorage.getItem('role'),
			fields: [{ key: 'action', label: 'Include' }, 'name', 'email', 'role', { key: 'expired_at', label: 'NDA' }, { key: 'delete', label: ' ' }],
			fields2: [
				{ key: 'start_date', label: 'Date From' }, 
				{ key: 'end_date', label: 'Date To' }, 
				{ key: 'site_name', label: 'Data Center' }, 
				{ key: 'room_name', label: 'Room' },
			],
			fields3: [{ key: 'item_name', label: 'Nama' }, 'serial', 'total', 'rack', { key: 'isDualFeed', label: 'Dual Feed' }, 'note', 'delete'],
			currentPage: 1,
			perPage: 5,
			totalRows: 1,
			isLoading: true,

			alertModal: false,
			buttonLoading: false,

			// date_now: moment(60 * 10 * 1000),
			companies: [],
			users: [],
			guest: {
				id: 0,
				name: '',
				id_card_number: '',
				email: '',
				mobile_phone: '',
				company_name: ''
			},
			items: {
				id: 0,
  			item_name: '',
  			serial: '',
  			rack: '',
  			total: 0,
  			isDualFeed: 0,
  			note: ''
			},
			items2: {
				id: 0,
  			item_name: '',
  			serial: '',
  			rack: '',
  			total: 0,
  			isDualFeed: 0,
  			note: ''
			},
			user: null,
			userPlaceholder: 'Pilih Authorized Person',
			activities: [],
			sites: [],
			rooms: [],
			room_visits: [],
			allowing_access: [],
			roomPlaceholder: 'Pilih Ruangan',

			visits: [],

			site_id: 0,
			room_id: 0,
			rack: '',
			user_id: +localStorage.getItem('uid'),
			activity_id: 0,
			description: '',
			start_date: this.date2Str(new Date(Date.now()), 'yyyy-MM-dd'),
			start_time: '09:00',
			end_date: this.date2Str(new Date(Date.now()), 'yyyy-MM-dd'),
			end_time: '17:00',
			workers: [],
			bastIn: [],
			bastOut: [],
			status: 'Pending',
			unplugCable: 1,
			unplugDevice: 1,
			isComply: false,
			mandatory: false,
			isApprove: 0,
			priority: 0,

			newGuest: false,
			loading: false,
			showMessage: false,
      message: '',

			bastStatus: false,
			picInclude: false,

			dualFeed: [
				{value: 1, label: 'Ya'}, 
				{value: 0, label: 'Tidak'}
			],
			newBastIn: false,
			newBastOut: false,
			loading2: false,
			showMessage2: false,
      message2: '',
			loading3: false,
			showMessage3: false,
      message3: '',

			selected: [], // Must be an array reference!
			selectedGuest: [],
			
			show: true,
			horizontal: { label:'col-3', input:'col-9' },
			/* activities: [
				'Audit', 
				'Dismantle',
				'Instalasi',
				'Maintenance',
				'Survey',
				'Troubleshooting'
			], */
			priorities: [
				'Tinggi',
				'Normal'
			],
			hardwares: [
				'Ya', 
				'Tidak'
			],
			cables: [
				'Ya', 
				'Tidak'
			],
			radioArea: ['Lokasi Data Center'],
			radioAct: ['Kegiatan'],
			radioVisit: ['Visitor']
		}
	},
	computed: {
  	filterResult() {
			let visits = this.visits
  		if (this.company_id) {
      	visits = visits.filter((item) => {
        	return item.company_id.includes(this.company_id)
      	})
    	}
    	return visits
  	}
	},
	methods: {
		alert() {
      this.$swal({
        icon: "success",
				title: "Hello world!",
				text: "Hello world!",
				closeOnClickOutside: false,
        buttons: {
          cancel: {
    				text: "Cancel",
    				value: null,
    				visible: true,
    				className: "",
    				closeModal: true,
  				},
  				confirm: {
    				text: "OK",
    				value: true,
    				visible: true,
    				className: "",
    				closeModal: true
  				}
        }
      });
    },
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 2000,
        dismissible: true
      })
    },
		date2Str(x, y) {
    	const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    	};
    	y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
        return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
    	});

    	return y.replace(/(y+)/g, function(v) {
        return x.getFullYear().toString().slice(-v.length)
    	});
		},
		selectValidator (val) {
			// return val ? val > 0 : false
			if (val > 0) {
				this.mandatory = true;
				return true;
			} else {
				this.mandatory = false;
				return false;
			}
		},
		selectValidatorYN (val) {
			// return val ? val.length >= 1 : false
			if (val.length >= 1) {
				this.mandatory = true;
				return true;
			} else {
				this.mandatory = false;
				return false;
			}
		},
		selectValidatorPrior (val) {
			if (val) {
				this.mandatory = true;
				return true;
			} else {
				this.mandatory = false;
				return false;
			}
		},
		inputValidator (val) {
			// return val ? val.length >= 7 : false
			if (val.length >= 7) {
				this.mandatory = true;
				return true;
			} else {
				this.mandatory = false;
				return false;
			}
		},
		cekDate() {
			let start = new Date(this.start_date + ' ' + this.start_time);
			let end = new Date(this.end_date + ' ' + this.end_time);
			if ( end > start) {
				this.isComply = true;
			} else {
				this.isComply = false;
				this.toast('Waktu Selesai Tidak Boleh Lebih Awal Daripada Waktu Mulai.', 'error');
			}
		},
		formValid() {
			if (this.selected.length > 0 && this.selectValidator() && this.selectValidatorYN() && this.selectValidatorPrior() && this.inputValidator()) {
				this.isComply = true;
				// this.mandatory = true;
				return true;
			} else {
				this.isComply = false;
				return false;
			}
		},
		toggleGuest() {
			let self = this
			self.guest = {
				name: '',
				id_card_number: '',
				email: '',
				mobile_phone: '',
				company_name: ''
			};
			self.newGuest = !self.newGuest;
			document.getElementById('guestName').focus();
		},
		toggleBastIn() {
			let self = this
			self.items = {
				id: 0,
  			item_name: '',
  			serial: '',
  			rack: '',
  			total: 0,
  			isDualFeed: 0,
  			note: ''
			},
			self.newBastIn = !self.newBastIn;
		},
		toggleBastOut() {
			let self = this
			self.items2 = {
				id: 0,
  			item_name: '',
  			serial: '',
  			rack: '',
  			total: 0,
  			isDualFeed: 0,
  			note: ''
			},
			self.newBastOut = !self.newBastOut;
		},
		companyChange(event) {
			this.userPlaceholder = 'Mencari..';
			this.company_id = event.target.value;
			this.getUsers(event.target.value);
			// this.filterResult();
			this.workers = [];
		},
		userChange(event) {
			this.getUser(event.target.value)
		},
		getCompanies (){
			const self = this;
			return http.get('/companies')
			.then(function (response) {
				const data = response.data.data;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['company_name'];
					items['value'] = data[i]['id'];
					self.companies.push(items)
				}

			}).catch(function (error) {
				console.log(error);
			});
		},
		getUsers(id) {
			let self = this;
			self.isLoading = true;
			self.users = [];
			return http.get('/users/company/' + id)
			.then(function (response) {
				const data = response.data.user;

				// self.workers = response.data.user;
				response.data.user.forEach((value, index) => {
    			self.workers.push(value);
				});
				
				if (data) {
					for (let i = 0; i < data.length; i++) {
						const items = {};
						items['label'] = data[i]['name'];
						items['value'] = data[i]['id'];

						// put only activated users into the list
						if (data[i]['status'] === 'Active') {
							self.users.push(items);
						}
					}
					self.userPlaceholder = 'Pilih Authorized Person';
				} else {
					self.userPlaceholder = 'Pilih Authorized Person';
				}

				self.isLoading = false;
				
			}).catch(function (error) {
				self.userPlaceholder = 'Pilih Authorized Person';
				console.log(error);
			});
		},
		getUser(id) {
			let self = this;
			return http.get('/users/' + id)
			.then(function (response) {
				// console.log(response.data.room)
				// self.user = response.data.user
				self.allowing_access = response.data.room;
				/* response.data.user.forEach((value, index) => {
    			self.user = value;
				}); */
			}).catch(function (error) {
				console.log(error);
			});
		},
		getSites (){
			let self = this;
			return http.get('/sites')
			.then(function (response) {
				const data = response.data.data;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['site_name'];
					items['value'] = data[i]['id'];
					self.sites.push(items)
				}
				
			}).catch(function (error) {
				console.log(error);
			});
		},
		siteChange(event) {
			// console.log(event.target.value)
			this.roomPlaceholder = 'Mencari..';
			this.room_id = 0;
			//this.getRooms(event.checked)
			this.getRooms(event.target.value)
		},
		getRooms (id){
			let self = this;
			self.rooms = [];
			return http.get('/roomrestrict/name/' + self.user_id + '/' + id)
			.then(function (response) {

				if (response.data.data) {
					const data = response.data.data;
        
					for (let i = 0; i < data.length; i++) {
						const items = {};
						items['label'] = data[i]['room_name'];
						items['value'] = data[i]['id'];
						self.rooms.push(items)
					}
				}
				
			}).catch(function (error) {
				console.log(error);
			});
		},
		getRoomsBySite(id) {
			const self = this;
			self.rooms = [];
			return http.get('/rooms/site/' + id)
			.then(function (response) {
				const data = response.data.room;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['room_name'];
					items['value'] = data[i]['id'];
					self.rooms.push(items)
				}
				self.getAccess(self.user_id, id);
			}).catch(function (error) {
				console.log(error);
			});
		},
		getAccess(user_id, site_id) {
			// console.log('user', user_id)
			// console.log('site', site_id)
			let self = this;
			self.allowing_access = [];
			
			return http.get('/roomrestrict/user/' + user_id + '/' + site_id)
				.then(function (response) {
					console.log(response.data.data)
					if (response.data.data) {
						self.allowing_access = response.data.data.split(',');
					}
				}).catch(function (error) {
					console.log(error);
				});
		},
		getActivities() {
			let self = this;
			return http.get('/activities')
			.then(function (response) {
				const data = response.data.data;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['activity_name'];
					items['value'] = data[i]['id'];
					self.activities.push(items)
				}
				
			}).catch(function (error) {
				console.log(error);
			});
		},
		getVisits() {
			let self = this;
			return http.get('/visits')
			.then(function (response) {
				let list = response.data.data
				self.visits = list.filter(function(item) {
    			return (item.status === "Pending" && item.company_id === self.company_id);  
				});
				self.isLoading= false;
			}).catch(function (error) {
				console.log(error);
				self.isLoading= false;
			});
		},
		
		formSubmit() {
			let self = this;
			self.buttonLoading = true;
			if (self.selected === []) {
				self.toast('Daftar Pengunjung Tidak Boleh Kosong.', 'error');
				self.buttonLoading = false;
			} else {
				return http.post('/visits', {
					user_id: self.user_id,
					company_id: self.company_id,
					site_id: self.site_id,
					room_id: self.room_visits.toString(),
					rack: self.rack,
					workers: self.selected.toString(),
					start_date: self.start_date + ' ' + self.start_time.toString(),
					end_date: self.end_date + ' ' + self.end_time.toString(),
					status: self.role !== 4 ? 'Draft' : self.picInclude ? 'Pending' : 'Draft',
					isApprove: self.isApprove,
					priority: self.priority === 'Tinggi' ? 1 : 0,
					unplugCable: self.unplugCable === 'Ya' ? 1 : 0,
					unplugDevice: self.unplugDevice === 'Ya' ? 1 : 0,
					activity_id: self.activity_id,
					detail_activity: self.description,
					isComply: self.isComply === true ? 1 : 0
				})
				.then(function (response) {
					// self.$router.go(-1);
					// console.log(response.data.data.id)
					self.toast('Berhasil Menambahkan ke Jadwal Kunjungan.', 'info');
					if (self.bastIn.length > 0) {
						self.createBast(response.data.data.id, 1, self.bastIn);
					}
					
					if (self.bastOut.length > 0) {
						self.createBast(response.data.data.id, 0, self.bastOut);
					}
					self.$router.push({ path: '/approval/visit-request' });
					self.buttonLoading = false;
				}).catch(function (error) {
					console.log(error);
					self.toast(error.message, 'error');
					self.buttonLoading = false;
				});
			}
		},
		createBast(id, status, data) {
			let self = this;
			let dest = status === 1 ? 'Masuk' : "Keluar";
			return http.post('/handover', {	
				visit_id: +id, 
				status: status, 
				first_party: '', 
				second_party: '', 
				officer_approval: '', 
				security_approval: '', 
 				data: data
			})
			.then(function (response) {
				self.toast('Berhasil Menambahkan Barang ' + dest + '.', 'info');
				// console.log(response);
			}).catch(function (error) {
				console.log(error);
			});
			
		},
		tracePic(role, event) {
			// is PIC Mgr include in workers list
			if (role === 'PIC Manager' && event.target.checked) {
				this.picInclude = true;
			} else 
			// is PIC Mgr not include in workers list
			if (role === 'PIC Manager' && !event.target.checked) {
				this.picInclude = false;
			}
			
			// this.formValid();
		},
		addGuest() {
			let self = this;
			self.loading = true;
			if (!self.guest.id) {
				return http.post('register', {
        	name: self.guest.name,
        	email: self.guest.email,
					mobile_phone: self.guest.mobile_phone,
					id_card_number: self.guest.id_card_number,
        	password: 'Secret@123!',
        	company_id: self.company_id,
        	note: self.guest.company_name,
        	role: 9
      	})
      	.then(() => {
					self.workers = [];
					self.getUsers(self.company_id);
					self.toggleGuest();
					self.loading = false;
      	})
      	.catch(err => {
        	self.message = 'Email already registered, Please use another email for register.';
        	self.showMessage = true;
        	self.loading = false;
        	console.log(err)
      	})
			} else {
				return http.put('users/' + self.guest.id, {
					name: self.guest.name,
					email: self.guest.email,
					company_id: self.selectedGuest.company_id,
					mobile_phone: self.guest.mobile_phone,
					role: self.selectedGuest.role,
					status: self.selectedGuest.status,
					note: self.guest.company_name,
					id_card_number: self.guest.id_card_number
      	})
      	.then(() => {
					self.workers = [];
					self.selectedGuest = [];
					self.getUsers(self.company_id);
					self.toggleGuest();
					self.loading = false;
      	})
      	.catch(err => {
        	self.message = err.message;
        	self.showMessage = true;
        	self.loading = false;
        	console.log(err)
      	})
			}
		},
		editGuest(uid) {
			let self = this;
			// self.$router.push({ path: '/settings/users/form/' + uid });
			self.guest = self.workers.filter(item => item.id === uid)[0];
			self.selectedGuest = self.workers.filter(item => item.id === uid)[0];
			if (self.selectedGuest.role === 'Guest') {
				self.selectedGuest.company_name = self.selectedGuest.note;
			}
			self.newGuest = !self.newGuest;
		},
		delGuest(uid, company) {
			let self = this;
			if (confirm('Yakin Ingin Menghapus Pengunjung Dari ' + company + ' ?')) {
				return http.delete('/users/' + uid)
					.then(function (response) {
						self.toast('Berhasil Menghapus Pengunjung Dari Daftar.', 'info');
						self.workers = self.workers.filter(row => row.id !== uid);
						// console.log(response);
					}).catch(function (error) {
						console.log(error);
					});
			}
		},
		bastChange(event) {
			this.bastStatus = event.target.value === 'Ya' ? true : false;
		},
		addItems(status) {
			let self = this;
			if (status === 'in') {
				if (self.items.name === '' || self.items.serial === '' || self.items.total === 0) {
					alert('Informasi Barang Tidak Lengkap!');
				} else {
					self.bastIn.push(self.items);
					self.items = {
						id: 0,
  					item_name: '',
  					serial: '',
  					rack: '',
  					total: 0,
  					isDualFeed: 0,
  					note: ''
					}
					self.newBastIn = false;
				}
			} else {
				if (self.items2.name === '' || self.items2.serial === '' || self.items2.total === 0) {
					alert('Informasi Barang Tidak Lengkap!');
				} else {
					self.bastOut.push(self.items2);
					self.items2 = {
						id: 0,
  					item_name: '',
  					serial: '',
  					rack: '',
  					total: 0,
  					isDualFeed: 0,
  					note: ''
					}
					self.newBastOut = false;
				}
			}
		},
		editItem(status, item) {
			let self = this;
			if (status === 'in') {
				self.items = item;
				self.items.id = '1';
				self.newBastIn = !self.newBastIn;
			} else {
				self.items2 = item;
				self.items2.id = '1';
				self.newBastOut = !self.newBastOut;
			}
		},
		delItem(status, item) {
			if (confirm('Yakin Ingin Menghapus Barang Dari BAST ?')) {
				let self = this;
				if (status === 'in') {
					self.bastIn = self.bastIn.filter(row => row !== item);
					self.toast('Berhasil Menghapus Barang Dari BAST.', 'info');
				} else {
					self.bastOut = self.bastOut.filter(row => row !== item);
					self.toast('Berhasil Menghapus Barang Dari BAST.', 'info');
				}
			}
		}
	},
	mounted() {
		// console.log(this.company_id)
		// this.getUser(this.user_id)
		this.getVisits().then(() => {
			return this.getSites();
		}).then(() => {
			return this.getUsers(this.company_id);
		}).then(() => {
			this.getActivities();
		})
	}
}
</script>
<style scoped>
.parent{
  position: absolute;
  overflow: visible;
  height: 100%;
}
.sticky{
  position: sticky;
  top: 90
}
</style>
